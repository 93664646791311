

// this is for show file name which is sumitted
/*{
    document.addEventListener("DOMContentLoaded", function() {
    
        var fileInput = document.getElementById("customFileInput");
        var fileNameBox = document.getElementById("customFileNameBox");

        fileInput.addEventListener("change", function() {
            var selectedFile = fileInput.files[0];
            if (selectedFile) {
                var fileName = selectedFile.name;
                fileNameBox.textContent = `${fileName}`;
            } else {
                fileNameBox.textContent = "No file selected";
            }
        });
    });
}*/


// this is increase decrease counter 
/*{ 
    document.addEventListener("DOMContentLoaded", function() {
    const decreaseButton = document.getElementById("decrease");
    const increaseButton = document.getElementById("increase");
    const countInput = document.getElementById("count");

    decreaseButton.addEventListener("click", () => {
        let currentValue = parseInt(countInput.value, 10);
        if (currentValue > 0) {
            countInput.value = currentValue - 1;
        }
    });

    increaseButton.addEventListener("click", () => {
        let currentValue = parseInt(countInput.value, 10);
        countInput.value = currentValue + 1;
    });
});
}*/

// Custom select dropdown

{
    function setupCustomSelect(selectElement) {
        const selectSelected = selectElement.querySelector(".select-selected");
        const selectItems = selectElement.querySelector(".select-items");

        // Toggle the dropdown when the selected item is clicked
        selectSelected.addEventListener("click", function () {
          selectItems.classList.toggle("visible");
        });
        console.log(selectItems);
        // Handle option selection
        selectItems.addEventListener("click", function (e) {
          if (e.target.tagName === "DIV") {
            //selectSelected.textContent = e.target.textContent;
            console.log(e.target);
            
            let dataSelected = e.target.innerHTML.replace("pictoSize","pictoSize m-0");
            /*const div = document.createElement("div");
            div.className="d-flex justify-content-center align-items-center";
            div.innerHTML = dataSelected;
            div.addEventListener("click", function () {
                selectItems.classList.toggle("visible");
            });
            selectSelected.innerHTML = div.innerHTML;*/
            selectSelected.innerHTML = '<div class="d-flex justify-content-center align-items-center">'+dataSelected+'</div>';
            selectSelected.addEventListener("click", function () {
                selectItems.classList.toggle("visible");
            });
            selectItems.classList.remove("visible");
            let currentInput = document.querySelector("#"+"input"+e.target.parentNode.parentNode.getAttribute("id"));
            if(currentInput)
                currentInput.value =e.target.getAttribute("value");
          }
        });

        // Close the dropdown when clicking outside of it
        document.addEventListener("click", function (e) {
          if (e.target !== selectSelected) {
            selectItems.classList.remove("visible");
          }
        });
  }

  // Call the function for each custom select dropdown
  let allSelect = document.querySelectorAll(".gcselect");
    if(allSelect)
        allSelect.forEach(function(obj){
            if(obj && obj.classList.contains('gcselect')){
                setupCustomSelect(obj);
                
                let newInput = document.createElement("input");
                newInput.type="hidden";
                newInput.name=obj.getAttribute("name");
                if(obj.getAttribute("value") != "")
                    newInput.value=obj.getAttribute("value");
                else 
                    newInput.value = "";
                newInput.id="input"+obj.getAttribute("id");
                obj.parentNode.appendChild(newInput);
            }
        });
}
   
